import Vue from "vue";
import VueRouter from "vue-router";
import VueMeta from "vue-meta";

import Home from "@/views/Home.vue";
import Terms from "@/views/Terms.vue";
import Cookies from "@/views/Cookies.vue";
import Error from "@/views/Error.vue";
import ThankYou from "@/views/ThankYou.vue";
import CampaignEnded from "@/views/CampaignEnded.vue";

Vue.use(VueMeta);
Vue.use(VueRouter);

function getRoutes() {
  return [
    {
      path: "/:catchAll(.*)",
      name: "campaignended",
      component: CampaignEnded,
    },
    // {
    //   path: "/",
    //   name: "home",
    //   component: Home,
    // },
    // {
    //   path: "/terms",
    //   name: "terms",
    //   component: Terms,
    // },
    // {
    //   path: "/cookies",
    //   name: "cookies",
    //   component: Cookies,
    // },
    // {
    //   path: "/thank-you",
    //   name: "thankyou",
    //   component: ThankYou,
    // },
    // {
    //   path: "/:catchAll(.*)",
    //   name: "error",
    //   component: Error,
    // },
  ];
}

const routes = getRoutes();

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (!to.hash) return { x: 0, y: 0 };
  },
});

export default router;
