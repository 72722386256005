<template>
  <div class="thankyou-grandparent">
    <section class="error-container">
      <img class="error-container__image" src="/assets/404.png" />

      <div class="error-container__text">
        <h1 class="error-container__text__main-paragraph">
          OOPS!
        </h1>
        <p>Denne siden eksisterer ikke.</p>
      </div>

      <div class="error-container__button-parent">
        <router-link to="/">
          <ButtonComponent
            class="error-container__button"
            theme="primary"
            label="HJEM"
          ></ButtonComponent>
        </router-link>
        <a
          href="https://contactus.mdlzapps.com/freiano/contact/no-NO/"
          target="_blank"
        >
          <ButtonComponent
            class="error-container__button"
            theme="primary"
            label="KONTAKT OSS"
          ></ButtonComponent>
        </a>
      </div>
    </section>
  </div>
</template>
<script>
import Error from "@/components/Error.vue";
import { ButtonComponent } from "vue-elder-button";

export default {
  components: {
    Error,
    ButtonComponent,
  },
};
</script>

<style lang="scss">
.error-container {
  text-align: center;

  padding: 50px;

  @media (max-width: $tablet) {
    width: 95%;
    padding: 50px 20px 50px 20px;
    margin-bottom: 125px;
  }

  @media (max-width: $mobile) {
    padding: 10px 15px 50px 15px;
    width: 100%;
  }

  &__image {
    display: block;
    margin: auto;
    width: 80%;
    max-width: 650px;
    margin-top: 15px;

    @media (max-width: $mobile) {
      margin-top: 2rem;
      margin-bottom: 2rem;
    }
  }

  &__text {
    max-width: 800px;
    margin: auto;
    padding: 2.5rem;

    @media (max-width: $mobile) {
      margin-top: 2rem;
    }
  }

  &__button-parent {
    overflow: hidden;
    display: flex;
    justify-content: center;
    gap: 50px;

    &__paragraph {
      font-size: 1.5rem;
      font-weight: 600;
    }

    @media (max-width: $mobile) {
      flex-direction: column;
      gap: 20px;
      margin-top: 10px;
    }
  }

  .button {
    padding: 1rem;
    width: 80%;
    max-width: 300px;
    margin-top: 25px;
    background-color: var(--primary);

    @media (max-width: $mobile) {
      margin-left: 50%;
      transform: translate(-50%);
      padding: 0.25rem;
    }

    &:hover {
      background-color: var(--primary);
      color: white;
    }

    span {
      font-size: 1.5rem;
    }
  }
}
</style>
