<template>
  <div class="hero-section-parent">
    <section class="hero-section"></section>

    <h1>PREMIEN</h1>

    <p>
      Eventyrbilen venter på deg! Nå kan du cruise rundt over fjell og daler i
      vakker natur med venner og familie. Kvikk Lunsj-bilen kommer som en 3-
      eller 6-seter, så her har du god plass til folk eller en madrass til to.
      Vinneren får låne bilen i én uke.

      <br />
      <br />

      Når turen går avtales med vinneren!
    </p>

    <router-link to="/terms">
      <ButtonComponent
        class="hero-section__button"
        theme="primary"
        label="KONKURRANSEVILKÅR"
      ></ButtonComponent>
    </router-link>
  </div>
</template>

<script>
import { ButtonComponent } from "vue-elder-button";
import { redirect } from "@/utils";

export default {
  components: {
    ButtonComponent,
  },
  methods: {
    scrollTo() {
      window.scrollTo({
        top: 2100,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style lang="scss">
.hero-section-parent {
  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;
  overflow-x: clip;

  padding-bottom: 8rem;

  width: 100vw;

  h1 {
    margin-top: 4rem;
  }

  p {
    max-width: 900px;
    margin: auto;
    text-align: center;
    margin-top: 10px;

    @media (max-width: $mobile) {
      max-width: 80%;
    }
  }
}

.hero-section {
  background-image: url("@/assets/elements/hero_desktop.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  width: 100vw;
  aspect-ratio: 16/7;

  margin-top: 50px;

  border-bottom-left-radius: 100% 20%;
  border-bottom-right-radius: 100% 20%;

  @media (max-width: $mobile) {
    background-image: url("@/assets/elements/hero_mobile.jpg");
    aspect-ratio: 12/16;
    border-bottom-left-radius: 100% 15%;
    border-bottom-right-radius: 100% 15%;
  }

  &__button {
    margin-top: 30px;
  }
}
</style>
