<template>
  <div class="thankyou-grandparent">
    <section class="campaign-ended">
      <div class="campaign-ended__image"></div>

      <div class="campaign-ended__text">
        <h1 class="campaign-ended__text__main-paragraph">
          KAMPANJEN ER NÅ AVSLUTTET
        </h1>
        <p class="campaign-ended__text__paragraph">
          Kampanjen er dessverre over, men i mellomtiden kan du utforske andre
          kampanjer og nyheter på freia.no eller besøke oss på Facebook.
        </p>
      </div>

      <div class="campaign-ended__button-parent">
        <a href="https://freia.no/" target="_blank">
          <ButtonComponent
            class="campaign-ended__button"
            theme="primary"
            label="FREIA.NO"
          ></ButtonComponent>
        </a>
        <a
          href="https://contactus.mdlzapps.com/freiano/contact/no-NO/"
          target="_blank"
        >
          <ButtonComponent
            class="campaign-ended__button"
            theme="primary"
            label="KONTAKT OSS"
          ></ButtonComponent>
        </a>
      </div>
    </section>
  </div>
</template>
<script>
import Error from "@/components/Error.vue";
import { ButtonComponent } from "vue-elder-button";

export default {
  components: {
    Error,
    ButtonComponent,
  },
};
</script>

<style lang="scss">
.campaign-ended {
  &__image {
    background-image: url("@/assets/elements/hero_desktop-notext.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    width: 100vw;
    aspect-ratio: 16/7;

    margin-top: 50px;

    border-bottom-left-radius: 100% 20%;
    border-bottom-right-radius: 100% 20%;

    @media (max-width: $mobile) {
      background-image: url("@/assets/elements/hero_mobile-notext.jpg");
      aspect-ratio: 12/16;
      border-bottom-left-radius: 100% 15%;
      border-bottom-right-radius: 100% 15%;
    }
  }

  text-align: center;

  @media (max-width: $tablet) {
    width: 95%;
    margin-bottom: 125px;
  }

  @media (max-width: $mobile) {
    width: 100%;
  }

  &__text {
    max-width: 800px;
    margin: auto;
    margin-top: 3rem;

    @media (max-width: $mobile) {
      margin-top: 3rem;

      max-width: 80%;
    }

    &__paragraph {
      padding-top: 25px;
      padding-bottom: 25px;
    }
  }

  &__button-parent {
    margin-top: 25px;
    margin-bottom: 50px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    gap: 50px;

    &__paragraph {
      font-size: 1.5rem;
      font-weight: 600;
    }

    @media (max-width: $mobile) {
      flex-direction: column;
      gap: 20px;
    }
  }

  .button {
    padding: 1rem;
    width: 80%;
    max-width: 300px;
    margin-top: 25px;
    background-color: var(--primary);

    @media (max-width: $mobile) {
      margin-left: 50%;
      transform: translate(-50%);
      padding: 0.25rem;
    }

    &:hover {
      background-color: var(--primary);
      color: white;
    }

    span {
      font-size: 1.5rem;
    }
  }
}
</style>
