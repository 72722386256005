<template
  ><div class="terms-container-parent">
    <div class="terms-container">
      <div class="terms-container__text">
        <h1>KONKURRANSEVILKÅR</h1>

        <br />
        <br />

        <div class="terms-container__text__sub-paragraph">
          <div>
            <p class="terms-container__text__sub-paragraph__subtitle__main ">
              <span> FREIA «VINN KVIKK LUNSJ-BILEN» KONKURRANSEVILKÅR </span>
            </p>

            <br />

            <p class="terms-container__text__sub-paragraph__subtitle">
              <span>ARRANGØR AV KONKURRANSEN</span>

              <br />
              Mondeléz Europe Services Norsk filial <br />Johan Throne Holsts
              Plass 1, <br />P.O. Box 6658 Rodeløkka, <br />NO-0502, Oslo
            </p>

            <br />

            <p class="terms-container__text__sub-paragraph__subtitle">
              <span>AKSEPT AV VILKÅR OG BETINGELSER</span><br />
              Ved å delta i konkurransen aksepterer deltakeren
              konkurransereglene. <br />Aldersgrense for å delta er 18 år.
            </p>

            <br />

            <p class="terms-container__text__sub-paragraph__subtitle">
              <span>PREMIE</span>

              <br />
              - En person vinner en låneperiode med Kvikk Lunsj bilen i 7 dager
              i 2025.

              <br />

              - Vinnerbil er en VW ID BUZZ (Elbil).

              <br />

              - I samråd med Mondeléz velger vinneren en 3-seter eller en
              6-seter for låneperioden.

              <br />

              - Premien består også av Kvikk Lunsj merch.

              <br />

              - Premien må aksepteres slik som den er, og kan ikke byttes inn
              mot kontanter. Mondeléz er ikke ansvarlige for eventuelle skader
              som måtte oppstå under transport av Kvikk lunsj bilen.

              <br />

              - Vinner svarer for eventuell gevinstskatt.
              <br />
              <br />
            </p>

            <p class="terms-container__text__sub-paragraph__subtitle">
              <span>VILKÅR FOR LÅNEPERIODE </span>

              <br />

              - Bilen kan kun kjøre i Norge

              <br />

              - Låneperioden avtales med Mondeléz minst 2 måneder før lånestart.

              <br />

              - Føreren av bilen må ha gyldig førerkort og kortet skal
              presenterer i samhold med låneperioden.

              <br />

              - Bilen er forsikret, men ved skade følger en egenandel på 4.000,-

              <br />

              - Mondeléz står for veihjelpkostnad bortsett fra om man kjører
              bilen tom for strøm, da er vinner ansvarlig.

              <br />

              - Bil hentes og leveres i Oslo om ikke annet er avtalt med vinner
              basert på vinners hjemsted.

              <br />

              - Vinneren er selv ansvarlig for ladeutgifter.

              <br />

              - Vinneren må selv stå for egen reiseforsikring og må fremlegges
              sammen med førerkort.

              <br />

              - Mondeléz dekker ingen kostnader for overnatting, mat eller andre
              utgifter på turen.

              <br />

              - Vinneren kommer til å få tilsendt forsikringsvilkår som må
              signeres

              <br />

              - Ved å delta i kampanjen gir vinneren arrangøren den absolutte og
              ugjenkallelige retten og lisensen til å bruke, publisere,
              offentlig vise, fremføre, kringkaste, stille ut og reprodusere
              sitt navn, uttalelser, video, stemme, opptak, intervjuer,
              fotografier og andre visuelle eller lydlige representasjoner eller
              likheter, helt eller delvis, individuelt eller i forbindelse med
              annet materiale, inkludert, men ikke begrenset til, tekst,
              fotografier eller bilder i enhver form (nå tilgjengelig og slik,
              som kan bli oppfunnet i fremtiden), inkludert, men ikke begrenset
              til, internett og andre elektroniske og sosiale medier og for alle
              formål, inkludert reklame, markedsføring, emballasje og handel
              over hele verden, uten begrensning med hensyn til måten de brukes
              på, hvor ofte de brukes eller hvor lenge de brukes eller for andre
              formål i forbindelse med denne kampanjen eller i forbindelse med
              markedsføring av arrangørens produkter eller på annen måte.
              Vinneren samtykker videre i at alt materiale som produseres i
              henhold til de foregående rettighetene, forblir og skal forbli
              arrangørens eiendom.
            </p>

            <br />

            <p class="terms-container__text__sub-paragraph__subtitle">
              <span>KAMPANJEPERIODE</span>
              <br />
              01.07.2024-31.10.2024
            </p>

            <br />

            <p class="terms-container__text__sub-paragraph__subtitle">
              <span>HVORDAN DELTA</span>
              <br />
              1. Gå til hjemmesiden til Freia (www.freia.no)
              <br />
              2. Klikk deg inn på konkurransen «Vinn Kvikk Lunsj-bilen»
              <br />
              3. Registrer deg med navn, adresse, mobil, e-postadresse og alder
              <br />
              4. Trykk delta, og du er med i trekningen av premien!
            </p>

            <br />

            <p class="terms-container__text__sub-paragraph__subtitle">
              <span>SLIK BLI VINNEREN PLUKKET UT</span>
              <br />
              Vinnerne av konkurransen trekkes tilfeldig, basert på alle som har
              lagt igjen personopplysninger og trykket delta. Det trekkes totalt
              1 vinner etter endt kampanjeperiode. Vinnerne blir kontaktet på
              oppgitt mobil eller e-postadresse. Dersom vi ikke kommer i kontakt
              med vinneren ila de 7 dagene etter opprettet kontakt, forbeholder
              vi oss retten til å diskvalifisere deltakeren og tildele premien
              til en reserve valgt ut på samme måte.
              <br />
              <br />
            </p>

            <p class="terms-container__text__sub-paragraph__subtitle">
              <span>DISKVALIFISERING</span>
              <br />
              Mondelez og andre partnere forbeholder seg retten til å
              diskvalifisere deltakere som bryter med konkurransereglene, deltar
              med upassende kommentarer eller forsøker å sabotere konkurransen.
              Disse deltagerne vil også bli utestengt. Upassende eller støtende
              kommentarer fjernes.
            </p>

            <br />

            <p class="terms-container__text__sub-paragraph__subtitle">
              <span>FORBEHOLD</span>
              <br />
              Mondelez og andre partnere i denne konkurransen tar ikke ansvar
              for eventuelle trykkfeil, eller andre utilsiktete misforståelser.
              Mondelez og andre partnere kan ikke holdes ansvarlig for
              materialet som sendes inn av deltakerne i konkurransen. Hvis, av
              en eller annen grunn, kampanjen ikke kan bli gjennomført som
              planlagt av grunner som er utenfor Mondelez sin kontroll, vil
              Mondelez reservere seg retten til å, når som helst og uten
              forvarsel, avbryte, avslutte, endre eller utsette hele eller deler
              av kampanjen. I den utstrekning loven tillater, vil arrangørene
              ikke være ansvarlige for kampanjen eller premien, og tar ikke
              særlig ansvar for deltakelse som er ugyldig på grunn av tekniske
              problemer utenfor arrangørens kontroll eller problemer angående
              tilgang til nettsiden. Alle internettkostnader og andre kostnader
              som kan oppstå forbundet med deltakelse i kampanjen, er
              utelukkende deltakerens ansvar.
              <br />
            </p>

            <br />

            <br />

            <p class="terms-container__text__sub-paragraph__subtitle">
              <span>PERSONOPPLYSNINGER</span>
              <br />
              Ved deltagelse i konkurransen kommer deltakeren til å måtte gi fra
              seg visse personopplysninger, som navn og kontaktinformasjon.
              Mondelez vil behandle personopplysninger for å oppfylle sine
              forpliktelser vedrørende konkurransen, blant annet for å kontakte
              deltakerne og dele ut premiene. Vinnerne vil kunne annonseres på
              Facebook-sidene til Freia og freia.no, presentert med fornavn og
              eventuelt by. Mottakelse av premie forutsetter at vinneren
              samtykker til dette. Etter vi har kontaktet vinneren av premien
              vil kontaktinformasjon bli slettet. Listen blir slettet senest 1
              måned etter endt kampanjeperiode.
            </p>

            <br />

            <p class="terms-container__text__sub-paragraph__subtitle">
              Du har rett til å tilbakekalle ditt samtykke til behandlingen av
              personopplysninger. Tilbakekalles dette samtykket innen vinnerne
              en kåret, anses du ikke lengre å oppfylle kravene for å delta i
              konkurransen, og vi kommer ikke til å benytte dine
              personopplysninger videre. Du har rett til gratis, en gang per
              kalenderår, etter skriftlig underskrevet søknad stilet til
              Mondelez, å få beskjed om hvilke personopplysninger Mondelez har
              om deg og hvordan disse behandles. Etter loven har du også rett
              til å be om korrigering av eventuelle uriktige personopplysninger
              Mondelez måtte ha.
              <br />
            </p>

            <br />

            <p class="terms-container__text__sub-paragraph__subtitle">
              Dine personopplysninger vil bli delt med nøye utvalgte
              tredjeparter, Consultix som lagrer personopplysninger, og Pulse
              Communications som håndterer det tekniske rundt konkurransen.
            </p>

            <br />

            <p class="terms-container__text__sub-paragraph__subtitle">
              For mer informasjon om behandling av personopplysninger,se her:
              <br />
              <a
                href="https://eu.mondelezinternational.com/privacy-notice?sc_lang=nb-no&siteId=6oyH%2ft29EQgVQQ6KPOLGAQ%3d%3d"
                target="_blank"
              >
                https://eu.mondelezinternational.com/privacy-notice?sc_lang=nb-no&siteId=6oyH%2ft29EQgVQQ6KPOLGAQ%3d%3d
              </a>

              <br />
            </p>

            <br />

            <p class="terms-container__text__sub-paragraph__subtitle">
              <span>KONTAKT</span>
              <br />
              Har du spørsmål om vilkårene eller konkurransen generelt,kan du ta
              kontakt via Mondelez forbrukerkontakt, tlf. 21 53 08 57.
              <br />
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="terms-footer">
      <h1>
        SJEKK UT KONKURRANSEN
      </h1>
      <router-link to="/">
        <ButtonComponent
          class="terms-footer__button"
          theme="primary"
          label="KONKURRANSE"
        ></ButtonComponent>
      </router-link>
    </div>
  </div>
</template>

<script>
import { ButtonComponent } from "vue-elder-button";

export default {
  components: {
    ButtonComponent,
  },
};
</script>

<style lang="scss">
.terms-container-parent {
  @media (max-width: $mobile) {
    position: relative;
    overflow-x: clip;
  }
}

.terms-container {
  text-align: center;

  padding: 75px;

  @media (max-width: $tablet) {
    width: 95%;
    padding: 50px 20px 50px 20px;
  }

  @media (max-width: $mobile) {
    padding: 50px 15px 50px 15px;
    width: 100%;
    margin-top: 85px;
  }

  &__image {
    position: absolute;
    width: 265px;
    left: -12.69%;
    top: 50px;

    @media (max-width: 1375px) {
      display: none;
    }
  }

  &__text {
    max-width: 820px;
    margin: auto;

    &__main-paragraph {
      font-size: 1.5rem;
      font-weight: 600;
      line-height: 28.8px;
      margin-bottom: 1.5rem;
    }

    &__sub-paragraph {
      text-align: left;
      line-height: 25px;

      &__title {
        font-weight: 600;
        font-size: 1rem;
        line-height: 25px;
      }

      &__subtitle {
        font-weight: 400;
        font-size: 1.125rem;

        &__main {
          @media (max-width: $mobile) {
            max-width: 40%;
            word-break: normal;
          }
        }
      }
    }
  }

  h2 {
    margin-top: 16px;
  }

  p {
    line-height: 1.6rem;
  }

  &__button-parent {
    margin-top: 75px;
    overflow: hidden;

    &__paragraph {
      font-size: 1.5rem;
      font-weight: 600;
    }
  }

  .button {
    padding: 1rem;
    width: 80%;
    max-width: 300px;
    margin-top: 25px;
    background-color: var(--primary);

    @media (max-width: $mobile) {
      margin-left: 50%;
      transform: translate(-50%);
      padding: 0.25rem;
    }

    &:hover {
      background-color: var(--primary);
      color: white;
    }

    span {
      font-size: 1.5rem;
    }
  }

  a {
    line-break: anywhere;
    color: white;
  }

  span {
    font-weight: 900;
    font-family: var(--paragraph-font);
    line-height: 26px;

    @media (max-width: $mobile) {
      font-size: 1.375rem;
    }
  }
}

.terms-footer {
  background-color: var(--tertiary);

  text-align: center;
  padding: 75px 0px;

  h1 {
    color: var(--primary);
  }

  &__button {
    width: 80%;
    max-width: 300px;
    margin-top: 25px;
    background-color: var(--primary);

    @media (max-width: $mobile) {
      margin-left: 50%;
      transform: translate(-50%);
      padding: 0.25rem;
    }

    &:hover {
      background-color: var(--primary);
      color: white;
    }

    span {
      font-size: 1.5rem;
    }
  }

  a {
    color: var(--secondary);
  }
}
</style>
