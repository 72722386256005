<template>
  <div class="container">
    <HeroSection />

    <Form />
  </div>
</template>

<script>
import HeroSection from "@/components/HeroSection.vue";
import Form from "@/components/Form.vue";

export default {
  components: {
    HeroSection,
    Form,
  },
};
</script>

<style lang="scss"></style>
